import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/chatBot",
  },
  {
    path: "/ChatBot",
    name: "ChatBot",
    component: () => import("../views/ChatBot/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.afterEach((to) => {
  // 如果路由有meta.title，则使用它；否则使用默认标题
  document.title = to.meta.title ;
});
export default router;
